import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #1e2c39;
  padding: 17px 20px;
  width: 100%;
  border-radius: 2px;

  @media only screen and (max-width: 1025px) {
    min-width: 210px;
  }
`;

export const Box = styled.div`
  text-align: left;

  :last-child {
    text-align: right;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    color: #fafafa;
  }

  span {
    font-weight: 600;
    font-size: 12px;
    color: #5a6473;

    &.variation-up {
      color: #4bd281;
    }

    &.variation-down {
      color: #dc3545;
    }
  }

  div {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: flex-end;
  }
`;
